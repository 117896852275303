import {
  Answer,
  Challenge,
  Mission,
  Team,
  TeamChallenge,
  TeamRole,
} from '@freddy/models';
import { OneTimeDisplayEnum } from '../config/one-time-display.enum';

export class InitializeGameStateAction {
  static readonly type = '[Game] Initialize game state';
  constructor(public readonly team: Team) {}
}

export class AttackTeamAction {
  static readonly type = '[Game] Attack team';
  constructor(public readonly targetTeamUid: string) {}
}

export class YourTeamIsAttackedAction {
  static readonly type = '[Game] Your team is attacked';
  constructor(
    public readonly attackerTeamUid: string,
    public readonly points: number,
  ) {}
}

export class OneTimeDisplayAction {
  static readonly type = '[Connect] One time display';

  constructor(public readonly key: OneTimeDisplayEnum) {}
}

export class ListenTeamsAction {
  static readonly type = '[Game] Will Listen to the teams collection';
}

export class StartChallengeAction {
  static readonly type = '[Game] Challenge a team';

  constructor(
    public readonly challenger: string,
    public readonly opponent: string,
    public readonly challenge: Challenge,
  ) {}
}

export class GoToChallengeAction {
  static readonly type = '[Game] To the challenge page';

  constructor(public readonly teamChallengeUid: string) {}
}

export class GoToMapAction {
  static readonly type = '[Game] To the map page';
}

export class GoToLanguagesSelectorAction {
  static readonly type = '[Game] to the languages selector page';
}

export class GoToCompanyLogoAction {
  static readonly type = '[Game] To the company logo page';
}

export class GoToEndGameAction {
  static readonly type = '[Game] To the end game page';
}

export class GoToMissionsCompletedAction {
  static readonly type = '[Game] To the missions completed page';
}

export class ListenAnswersAction {
  static readonly type = '[Game] Will Listen to the answers collection';
}

export class ListenTeamChallengesAction {
  static readonly type = '[Game] Will Listen to the team challenges collection';
}

export class ListenGameAction {
  static readonly type = '[Game] Will Listen to the ongoing game';
}

export class CreateNewTeamAction {
  static readonly type = '[Game] Create a new team and join a game';
  constructor(
    public readonly teamName: string | null,
    public readonly teamPhotoPathBlob: string | null,
    public readonly role: TeamRole = TeamRole.PLAYER,
  ) {}
}

export class CheckExistingTeamAction {
  static readonly type = '[Game] Check Existing Team';
}

export class JoinTeamAction {
  static readonly type = '[Game] Join an existing team';
  constructor(public readonly team: Team) {}
}

export class ListenToPlayerPositionAction {
  static readonly type = '[Game] Listen to player position';
  constructor() {}
}

export class ListenTeamsPositionsAction {
  static readonly type = '[Game] Listen to teams positions';
}

export class SubmitMissionAnswerAction {
  static readonly type = '[Game] Submit the mission answer';

  constructor(
    public readonly answer: string | null,
    public readonly mission: Mission,
    public readonly skipped: boolean = false,
  ) {}
}

export class SubmitChallengeAnswerAction {
  static readonly type = '[Game] Submit the mission challenge answer';

  constructor(
    public readonly answer: number | null | boolean,
    public readonly timeLeft: number,
    public readonly teamChallenge: TeamChallenge,
  ) {}
}

export class SaveOrUploadMissionAnswerAction {
  static readonly type = '[Game] Save or Upload the mission answer';

  constructor(public readonly answer: Answer) {}
}

export class CloseChallengeCurtainAction {
  static readonly type = '[Game] Close the challenge curtain';
}

export class OpenChallengeCurtainAction {
  static readonly type = '[Game] Open the challenge curtain';
}

export class ListenMissionCompletionAction {
  static readonly type = '[Game] Listen mission completion';
}

export class ListenGameEndAction {
  static readonly type = '[Game] Listen game end';
}

export class StartDYIGameAction {
  static readonly type = '[Connect] Start game';
}
